<template>
  <div>
    <Carousel />
    <div container mx-auto bg-white w-full px-24px py-40px lg:py-64px text-app-text-gray-dark text="16px lg:24px" leading-28px text-center>
      <h1 font-extrabold>
        Vítejte v naší zámečnické firmě, kde se tradice a kvalita spojují s inovacemi a designem
      </h1>
      <p mt-8px font-medium>
        S pýchou vám představujeme naši firmu, která má dlouholetou praxi v oblasti zámečnictví a nedávno rozšířila svůj repertoár o výrobu exkluzivního designového nábytku.
      </p>
    </div>
    <Categories />
  </div>
</template>
